var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden",attrs:{"min-height":"calc(100vh - 68px)","color":"accent"}},[_c('v-overlay',{attrs:{"value":_vm.displayProgress}},[_c('v-progress-circular',{attrs:{"size":40,"width":4,"color":"amber","indeterminate":""}})],1),[_c('b-breadcrumb',{attrs:{"items":_vm.breadCrumbs}})],_c('v-banner',{attrs:{"single-line":"","sticky":"","dark":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('BackActionBar',{on:{"goback":_vm.goback}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('ContextualActionBar',{attrs:{"title":"Piano Operativo - Dashboard Impatto"}})],1),_c('v-col',{staticClass:"pr-3 pl-0 pt-6 text-right",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.managePlanActivation()}}},'v-btn',attrs,false),on),[(_vm.statusPlan == 'Attiva')?_c('v-icon',[_vm._v("mdi-flash")]):_c('v-icon',[_vm._v(" mdi-close-box")])],1)]}}])},[(_vm.statusPlan == 'Attiva')?_c('span',[_vm._v("ATTIVA PIANO")]):_c('span',[_vm._v("CHIUDI PIANO")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":""},on:{"click":_vm.planExport}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("ESPORTA")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":""},on:{"click":_vm.goToDetail}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v("VEDI DETTAGLI")])])],1),_c('v-col',{staticClass:"d-flex flex-row align-center",staticStyle:{"margin-top":"-33px"},attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"padd",attrs:{"label":"PROD","color":"secondary","value":"","input-value":"true"},on:{"change":function($event){return _vm.switchAction(_vm.envSwitch)}},model:{value:(_vm.envSwitch),callback:function ($$v) {_vm.envSwitch=$$v},expression:"envSwitch"}})],1)],1)],1),_c('v-sheet',{staticClass:"overflow-y-auto pt-0",attrs:{"id":"scrolling-techniques-7","max-height":"calc(100vh - 157px)","color":"accent"}},[_c('v-container',{attrs:{"fluid":""}},[_c('perfect-scrollbar',[(_vm.loaded)?_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-col',{staticClass:"pb-0"},[[_c('v-sheet',{staticClass:"mx-auto",attrs:{"dark":"","elevation":"1"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.impactSummary),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_vm._v(" "+_vm._s(_vm.impactSummaryValues[ Object.keys(_vm.impactSummaryValues)[i] ])+" ")])],1)}),1)],1)],1)]],2),_c('v-col',{staticClass:"pb-0"},[_c('ChartAppImpact',{attrs:{"chart":this.dataPlan.chart}})],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-col',{staticClass:"pb-0"},[_c('TableDrilldown',{attrs:{"headersNames":[
                  {
                    text: 'Priority Group',
                    value: 'priorityGroup',
                    width: '60%',
                  },
                  { text: '# Server Prod', value: 'prod', width: '10%' },
                  { text: '# Server NO Prod', value: 'noProd', width: '10%' },
                  {
                    text: '# Server To Start',
                    value: 'toStart',
                    width: '10%',
                  },
                  {
                    text: '# Server To be Checked',
                    value: 'toBeChecked',
                    width: '10%',
                  } ],"rowsValues":_vm.numImpactedNodes,"table":'server'}})],1),_c('v-col',{staticClass:"pb-0"},[_c('TableDrilldown',{attrs:{"headersNames":[
                  {
                    text: 'Priority Group',
                    value: 'priorityGroup',
                    width: '80%',
                  },
                  {
                    text: 'Applicazioni',
                    value: 'applications',
                    width: '10%',
                  },
                  {
                    text: 'Servizi Infrastrutturali',
                    value: 'servicesInfra',
                    width: '10%',
                  } ],"rowsValues":_vm.numImpactedServices,"table":'application'}})],1)],1)],1):_vm._e()],1)],1)],1),_c('ModalConfirm',{attrs:{"dialogConfirm":_vm.openDialogConfirm,"text":'Vuoi chiudere il piano?'},on:{"open-dialog-confirm":_vm.updateDialogConfirm,"confirmed":function($event){return _vm.close()}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }