<template>
  <v-chart
    class="chart"
    :option="option"
    theme="enitheme"
    autoresize
    :loading="barLoading"
    :loadingOptions="barLoadingOptions"
  />
</template>

<script>
import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import theme from "@/assets/enitheme.json";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
]);
// registering custom theme
registerTheme("enitheme", theme);

export default {
  name: "ChartAppImpact",
  components: {
    VChart,
  },
  props: {
    chart: {
      default: [],
      Array,
    },
  },
  data() {
    return {
      barLoading: false,
      barLoadingOptions: {
        text: "Caricamento…",
        textColor: "#fff",
        color: "#fdd300",
        maskColor: "rgba(0, 0, 0, 0.7)",
      },
      option: {
        title: {
          text: "App Impattate per D&D",
          top: 10,
          left: "center",
          textStyle: { fontWeight: "normal", fontSize: "12" },
        },
        dataset: {
          dimensions: ["name", "value"],
          source: [],
        },
        xAxis: {},
        yAxis: {
          // type: "category",
          data: [],
          inverse: true,
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}",
        },
        legend: { top: 10, right: "40" },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        series: [
          {
            name: "Servizi",
            type: "bar",
            color: ["#FFC107"],
            data: [],
            emphasis: {
              itemStyle: {
                // Color in emphasis state.
                color: "#f4e071",
              },
              label: {
                show: true,
                // Text in emphasis.
                formatter: "Servizi: {c}",
              },
            },
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getSourceChart(this.$route.params.id);
  },
  methods: {
    getSourceChart() {
      var array = [];
      this.chart.forEach((item) => {
        array.push(item.name);
      });
      this.option.yAxis.data = array;
      this.option.series[0].data = this.chart;
      this.barLoading = false;
    },
  },
  watch: {
    option: function (val) {
      console.log("update option");
      return val;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 500px;
}
</style>
