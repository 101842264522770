<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template> <b-breadcrumb :items="breadCrumbs"></b-breadcrumb></template>

    <v-banner single-line sticky dark>
      <v-row>
        <v-col cols="2">
          <BackActionBar @goback="goback"></BackActionBar>
        </v-col>
        <v-col cols="8">
          <ContextualActionBar
            title="Piano Operativo - Dashboard Impatto"
          ></ContextualActionBar>
        </v-col>
        <v-col cols="2" class="pr-3 pl-0 pt-6 text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="managePlanActivation()"
              >
                <v-icon v-if="statusPlan == 'Attiva'">mdi-flash</v-icon>
                <v-icon v-else> mdi-close-box</v-icon>
              </v-btn>
            </template>
            <span v-if="statusPlan == 'Attiva'">ATTIVA PIANO</span>
            <span v-else>CHIUDI PIANO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="planExport"
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="goToDetail"
                ><v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>VEDI DETTAGLI</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-row align-center"
          style="margin-top: -33px"
        >
          <v-switch
            label="PROD"
            v-model="envSwitch"
            color="secondary"
            value
            input-value="true"
            class="padd"
            v-on:change="switchAction(envSwitch)"
          ></v-switch>
        </v-col>
      </v-row>
    </v-banner>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto pt-0"
      max-height="calc(100vh - 157px)"
      color="accent"
    >
      <v-container fluid>
        <perfect-scrollbar>
          <v-row v-if="loaded">
            <v-col cols="6" class="pa-0">
              <v-col class="pb-0">
                <template>
                  <v-sheet class="mx-auto" dark elevation="1">
                    <v-list dense>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, i) in impactSummary"
                          :key="i"
                        >
                          <v-list-item-icon>
                            <v-icon
                              v-text="item.icon"
                              color="secondary"
                            ></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{
                              impactSummaryValues[
                                Object.keys(impactSummaryValues)[i]
                              ]
                            }}
                          </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-sheet>
                </template>
              </v-col>
              <v-col class="pb-0">
                <ChartAppImpact :chart="this.dataPlan.chart"></ChartAppImpact>
              </v-col>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-col class="pb-0">
                <TableDrilldown
                  :headersNames="[
                    {
                      text: 'Priority Group',
                      value: 'priorityGroup',
                      width: '60%',
                    },
                    { text: '# Server Prod', value: 'prod', width: '10%' },
                    { text: '# Server NO Prod', value: 'noProd', width: '10%' },
                    {
                      text: '# Server To Start',
                      value: 'toStart',
                      width: '10%',
                    },
                    {
                      text: '# Server To be Checked',
                      value: 'toBeChecked',
                      width: '10%',
                    },
                  ]"
                  :rowsValues="numImpactedNodes"
                  :table="'server'"
                />
              </v-col>
              <v-col class="pb-0">
                <TableDrilldown
                  :headersNames="[
                    {
                      text: 'Priority Group',
                      value: 'priorityGroup',
                      width: '80%',
                    },
                    {
                      text: 'Applicazioni',
                      value: 'applications',
                      width: '10%',
                    },
                    {
                      text: 'Servizi Infrastrutturali',
                      value: 'servicesInfra',
                      width: '10%',
                    },
                  ]"
                  :rowsValues="numImpactedServices"
                  :table="'application'"
                />
              </v-col>
            </v-col>
          </v-row>
        </perfect-scrollbar>
      </v-container>
    </v-sheet>
    <ModalConfirm
      @open-dialog-confirm="updateDialogConfirm"
      :dialogConfirm="openDialogConfirm"
      :text="'Vuoi chiudere il piano?'"
      v-on:confirmed="close()"
    ></ModalConfirm>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import ChartAppImpact from "@/components/chart/ChartAppImpact.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import planIsActive from "@/mixins/planIsActive.js";
import download from "downloadjs";
import BackActionBar from "../components/core/BackActionBar.vue";
import TableDrilldown from "../components/core/TableDrilldown.vue";

export default {
  name: "OperatingCards",
  components: {
    ContextualActionBar,
    ChartAppImpact,

    ModalConfirm,
    BackActionBar,
    TableDrilldown,
  },
  mixins: [connectionMixin, planIsActive],
  data() {
    return {
      items: [],
      idPlan: this.$route.params.planId,
      dataPlan: JSON.parse(localStorage.getItem("dataPlan")),
      textUpdate: "",
      disableAlert: false,
      displayProgress: true,
      openDialogUpdatePlan: false,
      openDialogConfirm: false,
      loaded: true,
      envSwitch: false,
      impactSummary: [
        { text: "Servizi impattati", icon: "mdi-network" },
        { text: "Server impattati", icon: "mdi-server-network" },
        {
          text: "Tempo stimato riaccensione",
          icon: "mdi-calendar-range",
        },
      ],
      impactSummaryValues: {},
      numImpactedNodes: [],
      numImpactedServices: [],
      numServiceToLoad: null,
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Piano operativo",
          to: "/operationalplan",
        },
        {
          text: "Dashboard",
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.displayProgress = true;

    this.getUserProfile();
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),

    statusPlan: function () {
      return this.getActivation ? "Chiudi piano" : "Attiva";
    },

    updateProgress: function () {
      return this.displayProgress;
    },
  },
  watch: {
    numServiceToLoad: function () {
      if (this.numServiceToLoad === 0) {
        this.displayProgress = false;
        return false;
      }
    },
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data == "readonly") {
          this.$router.push("/");
        } else {
          this.getActivePlan();
          this.checkStatusPlan();
          this.getImpactSummary();
          this.getNumImpactedNodes();
          this.getNumImpactedServices();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    managePlanActivation() {
      if (this.activeplan.length > 0) {
        alert(
          "Esiste già un piano attivo. Chiuderlo prima di attivarne un altro"
        );
      } else {
        this.activePlan(this.idPlan);
      }
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    close() {
      this.closePlan(this.idPlan);
    },
    getImpactSummary() {
      this.impactSummaryValues = this.dataPlan.summary;
    },
    getNumImpactedNodes() {
      this.numImpactedNodes = this.dataPlan.nodes;
    },
    getNumImpactedServices() {
      this.numImpactedServices = this.dataPlan.services;
      this.displayProgress = false;
    },
    async activePlan(idPlan) {
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_ACTIVE_PLAN") +
            "/" +
            idPlan,
          null,
          this.opts
        );
        console.log(response);
        this.activate(true);
        localStorage.setItem("planIsActive", true);
        this.displayProgress = false;
        this.goToDetailPlan(idPlan);
      } catch (error) {
        this.activate(false);
        this.displayProgress = false;
        if (error.response.status == 409) {
          alert(
            "Il piano è stato già chiuso, non può essere attivato nuovamente"
          );
        } else {
          alert("Si prega di ricaricare la pagina");
        }
      }
    },
    goToDetailPlan(idPlan) {
      this.activate(true);
      localStorage.setItem("planIsActive", true);
      this.$router.push("/opplanimpactandprogress/" + idPlan);
    },
    goback() {
      this.$router.push("/operationalplan");
    },
    async planExport() {
      this.displayProgress = true;

      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_PLAN") +
            "/" +
            this.idPlan,
          this.optsXls
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        console.log(response);
        download(response.data, "export-piano.xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },
    checkStatusPlan() {
      let isActive = localStorage.getItem("planIsActive");
      if (isActive == "false") {
        this.activate(false);
      } else {
        this.activate(true);
      }
    },
    goToDetail() {
      let routeData = this.$router.resolve({
        name: "OpPlanFailoverGroupsId",
        params: {
          planId: this.$route.params.planId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    switchAction(value) {
      this.envSwitch = value;
      this.getDashboardImpatto(this.$route.params.planId);
    },
    async getDashboardImpatto(id, timeout = 0) {
      this.displayProgress = true;
      this.loaded = false;
      var bodyData = {
        onlyProd: this.envSwitch,
      };
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_DASHBOARD_IMPATTO") +
            "/" +
            id,
          bodyData,
          this.opts
        );
        if (response) {
          if (!response.data.completed) {
            await this.waitTimeout(timeout);
            this.getDashboardImpatto(id, timeout);
          } else {
            this.activate(false);
            this.dataPlan = response.data;
            this.getImpactSummary();
            this.getNumImpactedNodes();
            this.getNumImpactedServices();
            this.loaded = true;
            this.displayProgress = false;
          }
        }
      } catch (error) {
        console.log(error);
        this.displayProgress = false;
        alert("Si prega di ricaricare la pagina");
      }
    },
  },
};
</script>

<style scoped>
.ps {
  height: calc(100vh - 269px);
  width: 100%;
}
.hide {
  display: none !important;
}
.show {
  display: flex !important;
}

.padd {
  padding-left: 15px;
}
</style>
